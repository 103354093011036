import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	createBrowserRouter,
	RouterProvider
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './index.css';
import App from './App';
import LinkCount from './LinkCount';
import MyNavbar from './MyNavbar';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
	path: "/",
	element: <><MyNavbar /><App /></>,
	},
  {
	path: "/link-counter",
	element: <><MyNavbar /><LinkCount /></>
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
	<RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
