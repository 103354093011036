import React, { useState, FormEvent } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './linkCount.css';


function LinkCount() {
  const [url, setUrl] = useState("");
  const [message, setMessage] = useState("");


  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
	try {
	  let res = await fetch("https://europe-west1-homepage-a7f7c.cloudfunctions.net/githubLineCount", {
	    method: "POST",
		body: JSON.stringify({
		  url: url,
		}),
	  });
	
		let resJson = await res.json();
		const output = resJson["link_count"];
		if (res.status === 200) {
		  setMessage(`The repo has ${output} lines`)
		}
		else {
		  console.log("An error occurred");
		}
	} catch (err) {
	  console.log(err);
	}

  }
  return (
	<div>
	<Form id="link-form" className="d-flex justify-content-center vh-100 align-items-center text-center" onSubmit={onSubmit}>
		<Form.Group className="col-md-4 mx-auto">
			<Form.Label>Enter Github URL:</Form.Label>
			<Form.Control onChange={ e => setUrl(e.target.value) } type="text" placeholder="https://github.com/pocketbase/pocketbase"/>
			<Button className="submit-button" type="submit" variant="primary">Submit</Button>
			<p id="line-count-response">{message}</p>
		</Form.Group>
	</Form>
	</div>
  );
}

export default LinkCount;
