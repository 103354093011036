import React from 'react';
import Container from 'react-bootstrap/Container';
import './App.css';


function App() {
	return (
		<div>
			<div className="content-center d-flex">
				<Container>
					<div className="title-name">
						<h1>Rasmus Nygren</h1>
						<h2>Website under construction</h2>
					</div>
				</Container>
			</div>
		</div>
	);
}

export default App;
