import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function MyNavbar() {
  return (
		<Navbar bg="transparent" expand="lg" className="fixed-top">
		<Container className="nav-container">
			<Navbar.Brand href="#" as={Link} to="/">Home</Navbar.Brand>
			<Navbar.Collapse className="justify-content-center">
				<Nav>
					<Nav.Link href="https://www.linkedin.com/in/rasmus-nygren-5aa6351a5/">
						<FontAwesomeIcon icon={faLinkedin} />
					</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Container>
		</Navbar>
  );
}

export default MyNavbar;
